import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-estrutura.png'
import cn from 'classnames'

const EstruturaCargos = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>Estrutura de cargos</h1>
          <p>
            A primeira etapa a ser construída na elaboração de um plano de
            cargos e salários
          </p>
        </section>
        <div className={cn(st.imgText, st.green)}>
          <img src={img} alt="" />
          <p>
            Aqui no Sistema Unicred Conexão, utilizamos a estrutura de cargos
            como norteador para processos de atração e desenvolvimento de
            profissionais.
          </p>
        </div>
        <section className="container">
          <h2>Estrutura organizacional sistêmica</h2>
          <ul>
            <li>
              <section className={st.structureBox}>
                <h3 className={st.colorPurple}>Cooperados</h3>
                <ul>
                  <li>
                    <section>
                      <h3 className={st.colorGreenLight}>
                        Cooperativas Unicred singulares
                      </h3>
                      <ul>
                        <li>
                          <p className={st.colorOrange}>
                            Central multiregional
                          </p>
                          <p className={st.colorOrange}>Central RJ</p>
                          <p className={st.colorOrange}>Central RS</p>
                          <p className={st.colorOrange}>Central conexão</p>
                        </li>
                        <li>
                          <ul>
                            <li>
                              <section>
                                <h3 className={st.colorGreen}>
                                  Confederação ASS
                                </h3>
                                <p className={st.colorGreen}>
                                  Área de Serviços Sistêmicos
                                </p>
                              </section>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </section>
                  </li>
                </ul>
              </section>
            </li>
          </ul>
        </section>
      </main>
      <Footer location={location} />
    </>
  )
}

export default EstruturaCargos
